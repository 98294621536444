import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { auth, DOMAIN, is_already_authenticated } from '../api';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../job_tracker/PageHeader';
import './AuthForm.css';
import { toast, ToastContainer } from 'react-toastify';
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";


const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            is_already_authenticated().then(authenticated => {
                if(authenticated) {
                    navigate('/app');
                }
            })
        }
        checkAuth();
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();

        auth.post('/login/', {
            email: email,
            password: password
        })
        .then(res => {
            const token = res.data?.token;
            localStorage.setItem('token', token);
            navigate('/app');
        })
        .catch(err => {
            console.log(err);
            const error_msg = err?.response?.data?.error || 'Incorrect login credentials';
            toast.error(error_msg);
        });
    }

    const handleSuccess = (response) => {
        const token = response.credential;
        auth.post('/google_oauth_login/', {token}).then(res => {
            const token = res.data?.token;
            localStorage.setItem('token', token);
            navigate('/app'); 
        }).catch(err => {
            console.log(err);
            const error_msg = err?.response?.data?.error || 'Incorrect login credentials';
            toast.error(error_msg);
        });
      };
    
      const handleFailure = (response) => {
        console.log(response);
        toast.error('Incorrect login credentials');
      };
    
    return (
        <GoogleOAuthProvider clientId="132628962558-93gijml44r2ji6670u0umkbbta83cher.apps.googleusercontent.com">
            <PageHeader/>
            <div className='auth-form-container'>
                <form onSubmit={handleSubmit}>
                    {error && <p>{error}</p>}
                    <input
                        type="email"
                        placeholder="Email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                <br />
                    <input
                        type="password"
                        placeholder="Password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button type="submit">Login</button>
                <br />
                </form>
                <GoogleLogin
                    redirectUri={new URL('app/', DOMAIN).href}
                    onSuccess={handleSuccess}
                    onFailure={handleFailure}
                    buttonText="Sign in with Google"
                />
                <p>
                    New here? 
                </p>
                <button onClick={() => navigate('/register')}>Sign Up</button>
            </div>
            <ToastContainer position="bottom-right" hideProgressBar/>
        </GoogleOAuthProvider>
    );
};

export default Login;
