function formatted_date_string(date_string) {
    let date = new Date(date_string);
    
    // formatted date string and invalid date string
    // are NaN, just pass them through.
    if(isNaN(date)) return date_string;
    
    let options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    return date.toLocaleDateString('en-US', options);
}

function transform_created_at(row) {
    return {
        ...row,
        'created_at': formatted_date_string(row.created_at)
    }
}

function transform_job_details(row) {
    // check for existing transformed row
    if(row?.job_title) return row;
    let to_return = {
        ...row,
        'job_title': row.job_details?.job_title || "",
        'company': row.job_details?.company || "",
        'location': row.job_details?.location || "",
    }
    // don't delete job_details, used as ID source of truth when editing
    // job details
    return to_return;
}

// functions that take a row and return transformed row
// NOTE: These must be idempotent as row results are regularly
// transformed on themselves again
export const row_transformations = [
    transform_created_at,
    transform_job_details,
]