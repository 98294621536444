import PageHeader from "./job_tracker/PageHeader";
import './NotFound.css';
import { Link } from "react-router-dom";
export default function() {
    return (
        <>
            <PageHeader/>
            <div className="not-found-container">
                <h1>Here be dragons...</h1>
                <Link className="not-found-link" to='/'>Home</Link>
                <Link className="not-found-link" to='/login'>Login</Link>
                <Link className="not-found-link" to='/register'>Sign Up</Link>
            </div>
        </>
    )
}