import './App.css';
import { 
  Route, 
  Routes 
} from 'react-router-dom';
import JobTracker from './job_tracker/JobTracker';
import Register from './auth/Register';
import PrivateRoute from './auth/PrivateRoute';
import Login from './auth/Login';
import LandingPage from './job_tracker/LandingPage';
import NotFound from './NotFound';


function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<LandingPage/>} />
        <Route path="/app" element={<PrivateRoute><JobTracker/></PrivateRoute>} />

        {/* Registration and Login routes */}
        <Route path="/register" element={<Register/>} />
        <Route path="/login" element={<Login/>} />

        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </div>
  );
}


export default App;
