import './css/PageHeader.css';
import logo from '../icon.svg';
import { useNavigate } from 'react-router-dom';
import { auth, get_auth_headers, clearLocalToken} from '../api';
export default function PageHeader() {
    const navigate = useNavigate();
    return (
        <header className="header-container">
            <img className="header-logo" src={logo} alt="Logo" />
            <h1 className="header-text" onClick={() => {navigate('/')}}>JobHawk</h1>
        </header>
    )
}

export function PageHeaderWithLogout() {
    const navigate = useNavigate();
    function handleLogout(event) {
        event.preventDefault();
        auth.post('/logout/', {}, {headers: get_auth_headers()}).finally(() => {
            clearLocalToken();
            navigate('/')
        })
    }
    return (
        <header className="header-container-logout">
            <div className="logo-container">
                <img className="header-logo" src={logo} alt="Logo" />
                <h1 className="header-text" onClick={() => {navigate('/')}}>JobHawk</h1>
            </div>
            <button className="logout-button" onClick={handleLogout}>Logout</button>
        </header>
    )
}