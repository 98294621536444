import React, { useState, useEffect } from 'react';
import { authenticated_api } from '../api';
import { Link } from 'react-router-dom';
import JobPostingForm from './JobPostingForm';
import JobGrid from '../job_tracker/JobGrid';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/JobTracker.css';
import './css/Common.css';
import { PageHeaderWithLogout } from './PageHeader';
import { NewUserDetailsForm } from '../auth/Nux';

function JobTracker() {
  const [jobs, setJobs] = useState([]);
  const [formCount, setFormCount] = useState(0);
  const [showNux, setShowNux] = useState(false);

  useEffect(() => {
    authenticated_api().get('/job_posting/').then(response => {
      setJobs(response.data);

      // Logic to handle missing parsed data
      // check last element for empty data and show an info,
      // but only when not loading rows for the first time.
      const length = response.data.length;
      if(length && formCount) {
        const jobDetails = response.data[length - 1].job_details;
        const completeData = jobDetails.company && jobDetails.job_title && jobDetails.location
        if(!completeData) {
          toast.info("We couldn't find some Job Info, but you can still fill it out manually")
        }
      }
    }).catch(error => {
      toast.error('Failed to load Job Postings');
      console.log(error);
    });


    // formCount is a dependency so that the form submit can
    // trigger an API refresh
  }, [formCount]);

  useEffect(() => {
    authenticated_api().get('/should_show_nux/').then(response => {
      setShowNux(response.data.show_nux);
    })
  }, []);

  return (
    <>
      <PageHeaderWithLogout/>
      {
        showNux ? <NewUserDetailsForm setShowNux={setShowNux}/> : 
        <div className='job-tracker'>
          <JobPostingForm 
            count={formCount}
            setCount={setFormCount}
          />
          <JobGrid rows={jobs}/>
          {/* Configure with https://fkhadra.github.io/react-toastify/introduction/ */}
        </div>
      }
      <ToastContainer position="bottom-right" hideProgressBar/>
    </>

  );
}

export default JobTracker;