import axios from 'axios';

const API_HOST = process.env.REACT_APP_API_HOST;

export const DOMAIN = process.env.REACT_APP_DOMAIN;

// This is a function so that we get the most up to date
// token from local storage instead of using a stale value.
export function authenticated_api() {
  return axios.create({
    baseURL: new URL('api/', API_HOST).href,
    headers: get_auth_headers()
  })
}

export function get_auth_headers() {
  return {'Authorization': `Token ${localStorage.getItem('token')}`};
}

export const auth = axios.create({
  baseURL: new URL('auth/', API_HOST).href,
});

export const clearLocalToken = function() {
  localStorage.removeItem('token')
}

export async function is_already_authenticated() {
  const token = localStorage.getItem('token');
  if(!token) return false;

  try {
    auth.get('/check_token/', {
        headers: {
            Authorization: `Token ${token}`
        }
    }).then(res => {
      return res.status === 200
    })
    // If the request is successful, the token is valid
    return true
  } catch (error) {
      clearLocalToken();
      return false
  }
}