import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../icon.svg';
import grid from '../grid.png';
import notes from '../notes.png';
import addjob from '../addjob.gif';
import './css/LandingPage.css';

function NavBar() {
    return (
        <nav>
            <div className="container">
                <div className="logo-container">
                    <img className="header-logo" src={logo} alt="Logo" />
                    <h3 className="logo"><a href="#">JobHawk</a></h3>
                </div>
                <ul>
                    <li><a href="#overview">Overview</a></li>
                    <li><a href="#testimonials">Testimonials</a></li>
                    <li><a href="#faq">FAQs</a></li>
                </ul>
                <div className="nav-cta-container">
                    <Link to="/login" className="cta">Log In</Link>
                    <Link to="/register" className="cta">Sign Up</Link>
                </div>
            </div>
        </nav>
    );
}

function Header() {
    return (
        <header>
            <div className="container">
                <h1><span className="highlight">Streamline</span> Your Job Hunt</h1>
                <h2>The Ultimate Job Application Tracker</h2>
                <div className="sub">
                    <p>Ditch the excel spreadsheet or your notes app</p>
                    <p>Never lose track of a job application again</p>
                    <p>Organize your job hunt and focus on landing the job</p>
                </div>
                <Link to="/register" className="main-cta">Start Job Tracking Today!</Link>
            </div>
        </header>
    );
}

function Main() {
    return (
            <main>
                <section className="overview" id="overview">
                    <div className="container">
                        <div className="section">
                            <h2>🎯 All Your Job Applications in One Place</h2>
                            <p>Easily track every job application from submission to offer.</p>
                            <p>JobHawk's intuitive dashboard keeps all your job opportunities within reach.</p>
                            <img className="grid-img" src={grid} alt="Grid" />
                        </div>
                        <div className="section">
                            <h2>📝 Keep All Your Job Hunt Notes Organized</h2>
                            <p>Centralize your job hunt notes in one place.</p>
                            <p>Interview prep, company research, recruiter call notes...</p>
                            <p>Save the headache and manage your notes in JobHawk.</p>
                            <img className="grid-img" src={notes} alt="Notes" />
                        </div>
                        <div className="section">
                            <h2>🪄 Fill in job details <span className='highlight'>Automagically</span></h2>
                            <p>Let JobHawk do the heavy lifting for you.</p>
                            <p>Automatically fill in job details from job postings to save time and effort.</p>
                            <img className="grid-img" src={addjob} alt="Add job" />
                        </div>
                        <div className="section">
                            <h2>📈 Start Tracking Your Job Search Effortlessly</h2>
                            <p>Take control of your job hunt with powerful tools designed to streamline your search process.</p>
                            <p>From tracking applications to organizing interviews, JobHawk is your all-in-one job search management tool!</p>
                            <Link to="/register" className="main-cta">Sign Up for Free Today!</Link>
                        </div>
                    </div>
                </section>
                <section className="overview" id="testimonials">
                    <div className="container">
                        <div className="section">
                            <h2>❤️ What Our Users Say</h2>
                            <blockquote>
                                <p>"JobHawk has transformed my job search process. It's like having a personal assistant for my job hunt!"</p>
                                <p>"With JobHawk, I never miss a deadline or lose track of where I am in the application process!"</p>
                                <cite>A happy JobHawk user</cite>
                            </blockquote>
                        </div>
                        <div className="section">
                            <h2>🙋‍♀️ Join Thousands of Satisfied Users</h2>
                            <p>Don't just take our word for it. Join the growing community of job seekers</p>
                            <p>who have made JobHawk their go-to tool for managing their job search.</p>
                            <Link to="/register" className="main-cta">Sign Up for Free Today!</Link>
                        </div>
                    </div>
                </section>
                <section className="overview" id="faq">
                    <div className="container">
                        <div className="section">
                            <h2>🤔 Frequently Asked Questions</h2>
                            <h3>How does JobHawk help with my job search?</h3>
                            <p>JobHawk helps you keep track of all your job applications, interviews, and notes in one easy-to-use platform</p>
                            <h3>Is my information secure?</h3>
                            <p>Yes, JobHawk prioritizes your privacy and security. Your data is encrypted and stored securely.</p>
                            <h3>Is JobHawk free?</h3>
                            <p>100% Free! Sign up today and start using JobHawk's powerful features to streamline your job search.</p>
                        </div>
                        <div className="section">
                            <h2>Ready to Take Your Job Hunt to the Next Level?</h2>
                            <p>Sign up with JobHawk and streamline your job search today.</p>
                            <p>Let us help you organize your job applications, so you can focus on what really matters - landing your next job!</p>
                            <Link to="/register" className="main-cta">Sign Up Now!</Link>
                        </div>
                    </div>
                </section>
            </main>
    );
}

function Footer() {
    return (
        <footer>
            <div className="container">
                <p>&copy; 2024 JobHawk</p>
            </div>
        </footer>
    );
}

function LandingPage() {
    return (
        <div className="landing-page">
            <NavBar />
            <Header />
            <Main />
            <Footer />
        </div>
    );
}

export default LandingPage;
