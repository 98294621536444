import React, { useState } from 'react';
import { auth, DOMAIN } from '../api';
import { useNavigate } from 'react-router-dom';
import './AuthForm.css';
import PageHeader from '../job_tracker/PageHeader';
import { toast, ToastContainer } from 'react-toastify';
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";


function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password.length < 8) {
      toast.error('Password must be at least 8 characters long');
      return;
    }
    auth.post('/register/', {
      email: email,
      password: password
    })
    .then(res => {
      const token = res.data?.token;
      localStorage.setItem('token', token);
      navigate('/app');
    })
    .catch(err => {
      console.log(err);
      const error_msg = err?.response?.data?.error || 'Failed to register';
      toast.error(error_msg);
    });
  }

  const handleSuccess = (response) => {
    const token = response.credential;
    auth.post('/google_oauth_register/', {token}).then(res => {
      const token = res.data?.token;
      localStorage.setItem('token', token);
      console.log(res);
      navigate('/app'); 
    }).catch(err => {
      console.log(err);
      const error_msg = err?.response?.data?.error || 'Failed to register';
      toast.error(error_msg);
    });
  };

  const handleFailure = (response) => {
    console.log(response);
    toast.error('Failed to register');
  };

  return (
    <GoogleOAuthProvider clientId="132628962558-93gijml44r2ji6670u0umkbbta83cher.apps.googleusercontent.com">
      <PageHeader/>
      <div className="auth-form-container">
        <form onSubmit={handleSubmit}>
            <input 
              type="email" 
              name="email" 
              placeholder="Email"
              required
              value={email} onChange={e => setEmail(e.target.value)} />
          <br />
            <input 
              type="password" 
              name="password" 
              placeholder="Password"
              required
              value={password} onChange={e => setPassword(e.target.value)} />
          <button type="submit">Register</button>
        </form>
        <GoogleLogin
          redirectUri={new URL('app/', DOMAIN).href}
          onSuccess={handleSuccess}
          onFailure={handleFailure}
          buttonText="Register with Google"
        />
        <p>
            Already have an account?
        </p>
        <button onClick={() => navigate('/login')}>Log in</button>
      </div>
      <ToastContainer position="bottom-right" hideProgressBar/>
    </GoogleOAuthProvider>
  );
}

export default Register;
