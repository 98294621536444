import 'react-data-grid/lib/styles.css';
import { useEffect, useState } from 'react';
import DataGrid, { SelectColumn, valueFormatter } from 'react-data-grid';
import { row_transformations } from './grid/RowTransform';
import { textEditor } from 'react-data-grid';
import { application_status_selector } from './grid/Editors';
import { handleRowsChanged } from './grid/RowChangeHandler';
import JobDetailModal from './JobDetailModal';
import { deleteJobPostings } from './grid/RowDeleteHandler';
import './css/JobGridFilters.css'
import './css/JobGrid.css'

function jobCountText(numRows, application_status) {
    const job = numRows != 1 ? "jobs" : "job";
    const application = numRows != 1 ? "applications" : "application";
    const offer = numRows != 1 ? "offers" : "offer";
    const reject = numRows != 1 ? "rejections" : "rejection";
    const njobs = `${numRows} ${job}`;

    if (numRows == 0 && application_status === "ALL") {
        return "Get started by adding a job posting!"
    }

    switch(application_status) {
        case "Applied":
            return `${numRows} ${application} completed`
        case "Not Started":
            return `${numRows} ${application} to begin`
        case "Applying":
            return `${numRows} ${application} in progress`
        case "Interviewing":
            return `Interviewing for ${numRows} ${job}`
        case "Offered":
            return `${numRows} job ${offer} 🎉`
        case "Rejected":
            return `${numRows} ${reject}`
        default:
            return njobs
    }
}

function rowKeyGetter(row) {
    return row.id;
}

function urlFormatter(props) {
    const {row, column} = props;
    const val = row[column.key];
    return <><a href={val} target="_blank">{val}</a></>;    
}

function cellClickedFormatter(props, setClickedRow, childFormatter = valueFormatter) {
    // TODO: onMouseEnter controls hover
    // Hijacking the formatter to capture selected cell
    const { row, onRowChange, isCellSelected } = props;
    if(isCellSelected) {
        setClickedRow(row);
    }
    return (childFormatter(props))
}

function JobGrid(props) {
    const { rows } = props;
    const [initialRows, setInitialRows] = useState([]);
    const [gridRows, setGridRows] = useState([]);
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [clickedRow, setClickedRow] = useState();
    const [applicationStatusFilter, setApplicationStatusFilter] = useState("ALL")
    const [showModal, setShowModal] = useState(true);

    const columns = [
        SelectColumn,
        { 
            key: 'job_title', 
            name: 'Job Title',
            editor: textEditor,
            formatter(props){return cellClickedFormatter(props, setClickedRow)},
        },
        { 
            key: 'company', 
            name: 'Company',
            editor: textEditor,
            formatter(props){return cellClickedFormatter(props, setClickedRow)},
        },
        { 
            key: 'location', 
            name: 'Location',
            editor: textEditor,
            formatter(props){return cellClickedFormatter(props, setClickedRow)},
        },
        { 
            key: 'application_status', 
            name: 'Status',
            editor: application_status_selector,
            formatter(props){return cellClickedFormatter(props, setClickedRow)},
        },
        { 
            key: 'url', 
            name: 'Link',
            editor: textEditor,
            formatter(props){return cellClickedFormatter(props, setClickedRow, urlFormatter)},
        },
        {   
            key: 'created_at', 
            name: 'Date added',
            formatter(props){return cellClickedFormatter(props, setClickedRow)},
        },
    ];

    useEffect(() => {
        // When rows are first passed, need to set initialRows
        // When rows are updated optimistically, initialRows need to be updated
        setInitialRows(rows)
    }, [rows])

    useEffect(() => {
        // Applies filtering and transformation to initialRows state
        if (initialRows.length) {
            let filteredRows = initialRows;
            // Apply row filter            
            if ( applicationStatusFilter !== "ALL"){ 
                filteredRows = initialRows.filter(row => row.application_status === applicationStatusFilter)
            }
            // Apply row transformations
            const transformedRows = filteredRows.map(row => {
                let to_return = {...row}
                row_transformations.forEach(transform => {
                    to_return = transform(to_return)
                });
                return to_return
            });
            // This is the only place that setGridRows should be updated.
            // All updates to row data should be done to initialRows
            setGridRows(transformedRows);
        }
    }, [initialRows, applicationStatusFilter]);

    // Makes most recently selected row the most recently "clicked" row
    // which displays the modal
    useEffect(() => {
        if(selectedRows.size) {
            const mostRecentlySelectedIdx = Array.from(selectedRows)[selectedRows.size - 1];
            const mostRecentlySelectedRow = gridRows.find(row => row.id === mostRecentlySelectedIdx);
            setClickedRow(mostRecentlySelectedRow);
        }
    }, [selectedRows])

    // Hack to get modal close and open working.
    // when the user clicks close, show modal is set to false.
    // This effect resets the show modal state to true only when
    // the clickedRow state has changed, i.e the user has moved
    // to a different row.
    useEffect(() => {
        // When rows are selected (not clicked), don't show the modal
        if(clickedRow && !selectedRows.size) {
            setShowModal(true);
        }
    }, [clickedRow])

    // A row change refers to an edit made to some cell in the row
    const setInitialRowsOnRowChange = async function (newRows, rowsChanged) {
        if( rowsChanged.indexes.length ) {
            // Only set rows if patch went through successfully
            if( await handleRowsChanged(newRows, rowsChanged, initialRows) ) {
                setInitialRows(newRows);
            }
        }
    }

    const deleteEntry = async function() {
        const job_posting_ids = Array.from(selectedRows);
        if ( await deleteJobPostings(job_posting_ids) ) {
            setSelectedRows(new Set());
            setClickedRow(undefined);
            setInitialRows(initialRows.filter(row => !selectedRows.has(row.id))); 
        }
    }

    const gridClass = "rdg-light " + (clickedRow ? "job-grid-short" : "job-grid")

    return (
    <div className="job-grid-container">
        <div className='grid-treats'>
            <div className='filter-container'>
                <button className="button" onClick={() => {setApplicationStatusFilter("ALL")}}>All</button>
                <button className="button" onClick={() => {setApplicationStatusFilter("Not Started")}}>Not Started</button>
                <button className="button" onClick={() => {setApplicationStatusFilter("Applying")}}>Applying</button>
                <button className="button" onClick={() => {setApplicationStatusFilter("Applied")}}>Applied</button>
                <button className="button" onClick={() => {setApplicationStatusFilter("Interviewing")}}>Interview</button>
                <button className="button" onClick={() => {setApplicationStatusFilter("Offered")}}>Offer</button>
                <button className="button" onClick={() => {setApplicationStatusFilter("Rejected")}}>Rejected</button>
                {/* <button>Archived</button> */}
            </div>

            {selectedRows.size ? 
                (<div className="selected-container">
                    <button className="button delete-button" onClick={deleteEntry}>Delete</button>
                    <p className="selected-count">{selectedRows.size} selected</p>
                </div>)
            : <></>}

            {selectedRows.size ? 
                <></> :
                (<div className="selected-container">
                    <p className="selected-count">{jobCountText(gridRows.length, applicationStatusFilter)}</p>
                </div>)
            }
        </div>
        <DataGrid 
            rowKeyGetter={rowKeyGetter}
            columns={columns} 
            rows={gridRows} 
            onRowsChange={setInitialRowsOnRowChange}
            defaultColumnOptions={{
                sortable: true,
                resizable: true
            }}
            selectedRows={selectedRows}
            onSelectedRowsChange={setSelectedRows}
            className="rdg-light job-grid" />

        {showModal ? <JobDetailModal 
            clickedRow={clickedRow}
            setShowModal={setShowModal}/> : <></>}

            
    </div>);
}

export default JobGrid;