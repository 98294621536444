import { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { authenticated_api } from '../api';
import { toast } from 'react-toastify';

import './css/JobDetailModal.css';

function saveNotes(value, clickedRow) {
    const user_job_details_id = clickedRow.user_job_details;
    return authenticated_api().patch(`/user_job_details/${user_job_details_id}/`, {
        "notes": value
    }).then(
        response => {
            const success = response.status === 200
            if (success) {
                toast.success('Saved')
            } else {
                toast.error('Save failed')
                console.log(response.data)
            }
            return success;
        }
    ).catch(
        error => {
            toast.error('Save failed')
            console.log(error)
            return false;
        }
    );
}

function JobDetailModal(props) {
    const [value, setValue] = useState('');
    const { clickedRow, setShowModal } = props;
    const modalRef = useRef(null);

    const handleClickOutside = (event) => {
        // if (modalRef.current && !modalRef.current.contains(event.target)) {
        //     setShowModal(false);
        // }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            setShowModal(false);
        }
    }
    
    useEffect(() => {
        if(clickedRow) {
            const user_job_details_id = clickedRow.user_job_details;
            authenticated_api().get(`/user_job_details/${user_job_details_id}`).then(response => {
                setValue(response.data.notes);
            }).catch(error => {
                toast.error('Failed to load Job Details')
                console.log(error);
            });
        } else if(value) {
            // when clickedRow is set back to undefined
            // and value is defined, clear it
            setValue('')
        }
    }, [clickedRow]);


    useEffect(() => {
        // add event listener on mount
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyDown);
        // remove event listener on unmount
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);


    if (!clickedRow) return <></>;
    return (
        <div className='job-detail-container' ref={modalRef}>
            <div className='job-info-row-container'>
                <a className='job-title' href={clickedRow.url}>{clickedRow.job_title || "Fill in Job Title"}</a>
                <p className='job-company'>{clickedRow.company || "Fill in Company"}</p>
                <p className='job-info'>{clickedRow.location || "Fill in Location"}</p>
                <p className='job-info'>Status: {clickedRow.application_status}</p>
            </div>
            <div className='notes-container'>
                <h2 className='notes-title'>Notes</h2>
                <ReactQuill theme="snow" value={value} onChange={setValue} />
                <div className='notes-buttons'>
                    <button className='button' onClick={() => {saveNotes(value, clickedRow)}}>Save</button>
                    <button className='button' onClick={() => {setShowModal(false)}}>Close</button>
                </div>
            </div>
        </div>
    )   
}

export default JobDetailModal;