export const APPLICATION_STATUS_CHOICES = [
    "Not Started",
    "Applying",
    "Applied",
    "Interviewing",
    "Offered",
    "Rejected"
]

export const application_status_selector = function (p) {
    return (
        <select
        autoFocus
        value={p.row.application_status}
        onChange={(e) => p.onRowChange({ ...p.row, application_status: e.target.value }, true)}
        >
            {APPLICATION_STATUS_CHOICES.map((choice) => <option key={choice}>{choice}</option>)}
        </select>
    )
}