import { authenticated_api } from '../../api';
import { toast } from 'react-toastify';

const jobDetailFields = new Set([
    'job_title', 
    'company', 
    'location', 
])
const jobPostingFields = new Set([
    'application_status',
])

const patchChangedValue = function(endpoint, id, keyChanged, newKeyValue) {
    // return type is a Promise wrapping the result
    return authenticated_api().patch(`/${endpoint}/${id}/`, {
        [keyChanged]: newKeyValue 
    }).then(
        response => response.status === 200
    ).catch(
        error => {
            toast.error('Failed to update Job Posting')
            console.log(error)
            return false;
        }
    );
}

export const handleRowsChanged = function (newRows, rowsChanged, dataRows) {
    /*
        newRows = new state of grid after change
        rowsChange = index and column delta of change
        dataRows = props / data rows before change

        return type is a promise with the result of whether
        it was successfully patched.
    */
    // TODO - support bulk actions
    const idx = rowsChanged.indexes[0];
    const keyChanged = rowsChanged.column.key;
    const changedRow = dataRows[idx];
    const newGridRow = newRows[idx];
    const newKeyValue = newGridRow[keyChanged];
    
    let successfullyPatched = false;
    if( jobDetailFields.has(keyChanged) ) {
        const job_detail_id = changedRow.job_details.id;
        successfullyPatched = patchChangedValue('job_details', job_detail_id, keyChanged, newKeyValue);
    }
    if ( jobPostingFields.has(keyChanged) ) {
        const job_posting_id = changedRow.id;
        successfullyPatched = patchChangedValue('job_posting', job_posting_id, keyChanged, newKeyValue);
    }
    return successfullyPatched;
}