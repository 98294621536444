import React, { useState } from "react";
import { authenticated_api } from '../api';
import { toast } from 'react-toastify';
import './css/JobPostingForm.css';

const JobPostingForm = (props) => {
  const { count, setCount } = props;
  const [url, setUrl] = useState("");

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      const response = await authenticated_api().post("/job_posting/", { url });
      if (response.status === 201) {
        toast.success('Job Posting Added');
        setUrl("");
        setCount(count + 1);
      } else {
        toast.error('Failed to add Job Posting');
        console.log(response.data);
      }
    } catch (error) {
      toast.error('Failed to add Job Posting');
      console.error(error);
    }
  };

  return (
      <form className="form-container" onSubmit={handleSubmit}>
        <input
          type="text"
          value={url}
          onChange={event => setUrl(event.target.value)}
          placeholder="Add a Job Posting URL"
          className="form-input"
        />
        <button className="button" type="submit">Add</button>
      </form>

  );
};

export default JobPostingForm;


