import { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { authenticated_api } from '../api';
import './AuthForm.css';

const countryList = [
    "Remote",
    "United States",
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia (Plurinational State of)",
	"Bonaire, Sint Eustatius and Saba",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cayman Islands",
	"Central African Republic",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands",
	"Colombia",
	"Comoros",
	"Congo (the Democratic Republic of the)",
	"Congo",
	"Cook Islands",
	"Costa Rica",
	"Croatia",
	"Cuba",
	"Curaçao",
	"Cyprus",
	"Czechia",
	"Côte d'Ivoire",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Eswatini",
	"Ethiopia",
	"Falkland Islands [Malvinas]",
	"Faroe Islands",
	"Fiji",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories",
	"Gabon",
	"Gambia",
	"Georgia",
	"Germany",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard Island and McDonald Islands",
	"Holy See",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran (Islamic Republic of)",
	"Iraq",
	"Ireland",
	"Isle of Man",
	"Israel",
	"Italy",
	"Jamaica",
	"Japan",
	"Jersey",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea (the Democratic People's Republic of)",
	"Korea (the Republic of)",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macao",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia (Federated States of)",
	"Moldova (the Republic of)",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Montserrat",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"Northern Mariana Islands",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine, State of",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Republic of North Macedonia",
	"Romania",
	"Russian Federation",
	"Rwanda",
	"Réunion",
	"Saint Barthélemy",
	"Saint Helena, Ascension and Tristan da Cunha",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Martin (French part)",
	"Saint Pierre and Miquelon",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Sint Maarten (Dutch part)",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia and the South Sandwich Islands",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan",
	"Suriname",
	"Svalbard and Jan Mayen",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan",
	"Tajikistan",
	"Tanzania, United Republic of",
	"Thailand",
	"Timor-Leste",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates",
	"United Kingdom of Great Britain and Northern Ireland",
	"United States Minor Outlying Islands",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela (Bolivarian Republic of)",
	"Viet Nam",
	"Virgin Islands (British)",
	"Virgin Islands (U.S.)",
	"Wallis and Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
	"Åland Islands"
];

const industries = [  
    "Computer Software",
    "Computer Hardware",
    "Information Technology & Services",
    "Design",
    "Hospital & Health Care",
    "Construction",
    "Education Management",
    "Retail",
    "Financial Services",
    "Telecommunications",
    "Higher Education",
    "Oil & Energy",
    "Automotive",
    "Accounting",
    "Real Estate",
    "Marketing and Advertising",
    "Banking",
    "Management Consulting",
    "Insurance",
    "Government Administration",  
    "Logistics and Supply Chain",
    "Consumer Electronics",
    "Legal Services",
    "Apparel & Fashion",
    "Airlines",
    "Aviation & Aerospace",
    "Research",
    "Machinery",
    "Hospitality",
    "Pharmaceuticals",
    "Environmental Services",
    "Nonprofit Organization Management",
    "Electrical/Electronic Manufacturing",
    "Military",
    "Consumer Goods",
    "Publishing",
    "Packaging and Containers",
    "Motion Pictures and Film",
    "Broadcast Media",
    "Entertainment",
    "Health, Wellness and Fitness",
    "Other", // TODO
    ];


const employmentTypes = ["Full Time Role", "Internship", "Part Time Role", "Contract Role"]

export const NewUserDetailsForm = (props) => {
    const { setShowNux } = props;
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [yoe, setYoe] = useState(0);
    const [industry, setIndustry] = useState(industries[0]);
    const [otherIndustry, setOtherIndustry] = useState('');
    const [country, setCountry] = useState(countryList[0]);
    const [employmentType, setEmploymentType] = useState(employmentTypes[0]);
    const [linkedinUrl, setLinkedinUrl] = useState('');
    const [desiredSalary, setDesiredSalary] = useState(0);

    // Whenever industry changes, set other industry back to
    // default so that the newly selected industry is not
    // overridden
    useEffect(() => {
        setOtherIndustry('')
    }, [industry])

    const handleSubmit = async (e) => {
        e.preventDefault();
        let post_data = {
            // required fields
            'first_name': firstName,
            'last_name': lastName,
            'yoe': yoe,
            'industry': otherIndustry || industry,
            'country': country,
            'employment_type': employmentType,
        }

        // Only add optional fields if the user specified them 
        if(linkedinUrl) {
            post_data['linkedin_url'] = linkedinUrl;
        }
        if(desiredSalary) {
            post_data['desired_salary'] = desiredSalary;
        }

        authenticated_api().post('/user_details/', post_data).then(res => {
            if (res.status === 201) {
                console.log('success', res.data)
                toast.success('Welcome to JobHawk, ' + firstName);
                setShowNux(false);
            } else {
                toast.error('Something went wrong');
                console.log(res.data);
            }
        }).catch(err => {
            toast.error('Something went wrong');
            console.log(err);
        });
    }
    return (
        <>
        <div className="auth-form-container">
            <h2 className="form-title">Tell us a little bit about you</h2>
            <form onSubmit={handleSubmit}>
                <label className="required-label">First Name</label>
                <input
                    type="text"
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <br />
                <label className="required-label">Last Name</label>
                <input
                    type="text"
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                <br />
                <label className="required-label">Years of Experience</label>
                <input
                    type="number"
                    required
                    value={yoe}
                    onChange={(e) => setYoe(e.target.value)}
                />
                <br />
                <label className="required-label">Industry</label>
                
                {/* Industry */}
                <select
                    id="industry"
                    value={industry}
                    onChange={(e) => setIndustry(e.target.value)}
                >
                    {industries.map(e => <option value={e}>{e}</option>)}
                </select>
                <br />
                {industry === "Other" ? 
                    <>
                        <label className="required-label">Which Industry are you in?</label>
                        <input
                            type="text"
                            required
                            value={otherIndustry}
                            onChange={(e) => setOtherIndustry(e.target.value)}
                        />
                        <br /> 
                    </>
                : <></>}

                {/* Employment Type */}
                <label className="required-label">I'm looking for a</label>
                <select 
                    id="employment-type" 
                    value={employmentType} 
                    onChange={(e) => setEmploymentType(e.target.value)}
                >
                    {employmentTypes.map(e => <option value={e}>{e}</option>)}
                </select>
                <br />


                {/* Country */}
                <label className="required-label">I'm applying to jobs in</label>
                <select 
                    id="country" 
                    value={country} 
                    onChange={(e) => setCountry(e.target.value)}
                >
                    {countryList.map(e => <option value={e}>{e}</option>)}
                </select>
                <br />

                {/* Optional Fields */}
                <label>LinkedIn</label>
                <input
                    type="url"
                    value={linkedinUrl}
                    onChange={(e) => setLinkedinUrl(e.target.value)}
                />
                <br />
                <label>Desired Salary</label>
                <input
                    type="number"
                    value={desiredSalary}
                    onChange={(e) => setDesiredSalary(e.target.value)}
                />
                <br />
                <button type="submit">Start Tracking Jobs</button>
            </form>
        </div>
        </>
    )
}