import { authenticated_api } from "../../api"
import { toast } from 'react-toastify';


export const deleteJobPostings = function(job_posting_ids) {
    return authenticated_api().delete('/job_posting/bulk_delete/', {
        data: job_posting_ids
    }).then(
        response => {
            const success = response.status === 204;
            if( success ) {
                const num_deleted = job_posting_ids.length
                if (num_deleted === 1) {
                    toast.success(`Job Posting Deleted`);
                } else {
                    toast.success(`${job_posting_ids.length} Job Postings Deleted`);
                }
            } else {
                toast.error("Failed to delete Job Postings");
                console.log(response.data);
            }
            return success
        }
    ).catch(
        error => {
            toast.error("Failed to delete Job Postings");
            console.log(error);
            return false;
        }
    );
}