import React, { useEffect, useState } from 'react';
import { Route, Navigate, useNavigate } from 'react-router-dom';
import { is_already_authenticated } from '../api';

const PrivateRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);    
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
        is_already_authenticated().then(authenticated => {
            setIsAuthenticated(authenticated);
            if(!authenticated) {
                navigate('/login')
            }
        })
    }
    checkAuth();
  }, []);

  return isAuthenticated ? children : null;
};

export default PrivateRoute;
